<template>
  <v-dialog
    :value="value"
    @input="close"
    max-width="800px"
  >
    <template v-if="!isLoadingData && data">
      <!-- #region TEXTILE -->
      <v-card class="container-card" v-if="data && data.type === 'TEXTILE'">
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <h3>{{ $t("common.material-forms.details.title") }}</h3>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- #region general -->
        <v-card-text>
          <v-col cols="12">
            <div class="row justify-space-between">
              <v-col cols="6">
                <div
                  class="text-h6 primary--text font-weight-bold text-uppercase"
                >
                  {{ $t("preview-materials.textile.general.title") }}
                </div>
              </v-col>
              <v-col cols="6">
                <div>
                <span class="primary--text font-weight-bold">
                  {{
                    $t(
                      "preview-materials.textile.general.last-submission-title"
                    )
                  }}
                </span>
                  {{ formatDatetime(data.last_submission) }}
                </div>
              </v-col>
            </div>

            <v-divider class="mt-2" />
          </v-col>

          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.general.completed-title") }}
              </div>
              <v-icon
                v-if="data.general.completed === true"
                small
                class="ml-2"
                color="green darken-2"
              >
                fas fa-check
              </v-icon>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.general.component") }}
              </div>
              <div v-if="data.component" class="ml-2">
                {{ data.component }}
              </div>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.general.is-agent") }}
              </div>
              <v-icon v-if="data.isAgent" class="ml-2" color="primary">
                fas fa-check
              </v-icon>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.general.supplier") }}
              </div>
              <v-icon
                v-if="data.supplier"
                small
                class="ml-2"
                color="green darken-2"
              >
                fas fa-check
              </v-icon>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{
                  $t(
                    "preview-materials.textile.general.component-composition-title"
                  )
                }}
              </div>
              <div v-if="data.general.componentComposition" class="ml-2">
                {{ data.general.componentComposition }}
              </div>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>

          <!-- #region coordinates suppliers -->
          <v-expansion-panels flat style="margin-left: -12px">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="primary--text font-weight-bold">
                  {{
                    $t(
                      "preview-materials.textile.general.details-suppliers-title"
                    )
                  }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="d-flex justify-space-around">
                  <!-- #region dyeing supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.dyeing-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.name"
                              )
                            }}
                            {{ data.general.dyeingSupplierDetails.name || "n/a" }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.dyeingSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.dyeingSupplierDetails.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.dyeingSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion -->
                  <!-- #region fiber supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.fiber-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.name"
                              )
                            }}
                            {{ data.general.fiberSupplierDetails.name || "n/a" }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.email"
                              )
                            }}
                            {{ data.general.fiberSupplierDetails.email || "n/a" }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.fiberSupplierDetails.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.dyeing-supplier-details.phone"
                              )
                            }}
                            {{ data.general.fiberSupplierDetails.phone || "n/a" }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion fiber supplier details -->
                </v-row>

                <v-row class="d-flex justify-space-around">
                  <!-- #region finishing supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.finishing-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.finishing-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.finishingSupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.finishing-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.finishingSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.finishing-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.finishingSupplierDetails.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.finishing-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.finishingSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion finishing supplier details -->
                  <!-- #region knitting supplier details-->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.knitting-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.knitting-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.knittingSupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.knitting-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.knittingSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.knitting-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.knittingSupplierDetails.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.knitting-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.knittingSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion knitting supplier details -->
                </v-row>

                <v-row class="d-flex justify-space-around">
                  <!-- #region laundry supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.laundry-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.laundry-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.laundrySupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.laundry-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.laundrySupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.laundry-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.laundrySupplierDetails.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.laundry-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.laundrySupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion laundry supplier details -->
                  <!-- #region printing supplier details" -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.printing-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.printing-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.printingSupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.printing-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.printingSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.printing-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.printingSupplierDetails.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.printing-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.printingSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion printing supplier details -->
                </v-row>

                <v-row class="d-flex justify-space-around">
                  <!-- #region spinning supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.spinning-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.spinning-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.spinningSupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.spinning-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.spinningSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.spinning-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.spinningSupplierDetails.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.spinning-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.spinningSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion spinning supplier details -->
                  <!-- #region washing ginning supplier details -->
                  <v-col cols="5">
                    <v-row align="center" no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.washing-ginning-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.washing-ginning-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.washingGinningSupplierDetails.name ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.washing-ginning-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.washingGinningSupplierDetails.email ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.washing-ginning-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.washingGinningSupplierDetails
                                .address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.washing-ginning-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.washingGinningSupplierDetails.phone ||
                              "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion -->
                </v-row>

                <v-row class="ml-4">
                  <!-- #region weaving supplier details -->
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.textile.general.weaving-supplier-details.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.weaving-supplier-details.name"
                              )
                            }}
                            {{
                              data.general.weavingSupplierDetails.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.weaving-supplier-details.email"
                              )
                            }}
                            {{
                              data.general.weavingSupplierDetails.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.weaving-supplier-details.address"
                              )
                            }}
                            {{
                              data.general.weavingSupplierDetails.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.textile.general.weaving-supplier-details.phone"
                              )
                            }}
                            {{
                              data.general.weavingSupplierDetails.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- #endregion weaving supplier details -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- #endregion coordinates suppliers -->
        </v-card-text>
        <!-- #endregion general -->

        <!-- #region fibers -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.fibers.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="6">
            <v-row no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.fibers.completed-title") }}
              </div>
              <v-icon
                v-if="data.fibers.completed === true"
                small
                class="ml-2"
                color="green darken-2"
              >
                fas fa-check
              </v-icon>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-row no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.fibers.fibers-length-title") }}
              </div>
              <div class="ml-2 font-weight-bold">
                {{ data.fibers.fiberLength }}
              </div>
            </v-row>
          </v-col>
        </v-card-text>

        <!-- #endregion fibers -->

        <!-- #region dyeing -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.dyeing.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12">
            <v-row no-gutters class="ml-1">
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.textile.dyeing.completed-title") }}
              </div>
              <v-icon
                v-if="data.dyeing.completed === true"
                small
                class="ml-2"
                color="green darken-2"
              >
                fas fa-check
              </v-icon>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>

          <v-card-text>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{ $t("preview-materials.textile.dyeing.bleaching-title") }}
                    </div>
                    <div v-if="data.dyeing.bleaching" class="ml-2">
                      {{ data.dyeing.bleaching }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t(
                          "preview-materials.textile.dyeing.bleaching-chemical-title"
                        )
                      }}
                    </div>
                    <div v-if="data.dyeing.bleachingChemical" class="ml-2">
                      {{ data.dyeing.bleachingChemical }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="mt-6" cols="12">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t(
                          "preview-materials.textile.dyeing.dyeing-chemical-title"
                        )
                      }}
                    </div>
                    <div v-if="data.dyeing.dyeingChemical" class="ml-2">
                      {{ data.dyeing.dyeingChemical }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t("preview-materials.textile.dyeing.dyeing-stage-title")
                      }}
                    </div>
                    <div v-if="data.dyeing.dyeingStage" class="ml-2">
                      {{ data.dyeing.dyeingStage }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-6">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t(
                          "preview-materials.textile.dyeing.dyeing-technique-title"
                        )
                      }}
                    </div>
                    <div v-if="data.dyeing.dyeingTechnique" class="ml-2">
                      {{ data.dyeing.dyeingTechnique }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t(
                          "preview-materials.textile.dyeing.fixation-technique-title"
                        )
                      }}
                    </div>
                    <div v-if="data.dyeing.fixationTechnique" class="ml-2">
                      {{ data.dyeing.fixationTechnique }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="mt-6">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <div class="primary--text font-weight-bold">
                      {{
                        $t(
                          "preview-materials.textile.dyeing.pretreatment-applied-title"
                        )
                      }}
                    </div>
                    <div v-if="data.dyeing.pretreatmentApplied" class="ml-2">
                      {{ data.dyeing.pretreatmentApplied }}
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card-text>
        <!-- #endregion dyeing -->

        <!-- #region finishing -->

        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.finishing.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.textile.finishing.completed-title")
                    }}
                  </div>
                  <v-icon
                    v-if="data.finishing.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.chemical-finishing-application-nature-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.finishing.chemicalFinishingApplicationNature"
                    class="ml-2"
                  >
                    {{ data.finishing.chemicalFinishingApplicationNature }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.chemical-finishing-method-title"
                      )
                    }}
                  </div>
                  <v-col cols="12">
                    <div v-if="chemicalFinishingMethod.length">
                      <ul
                        v-for="(item, index) in chemicalFinishingMethod"
                        :key="index"
                      >
                        <li>{{ item }}</li>
                      </ul>
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.denim-finishing-method-title"
                      )
                    }}
                  </div>
                  <v-col cols="12">
                    <div v-if="denimFinishingMethod.length">
                      <ul
                        v-for="(item, index) in denimFinishingMethod"
                        :key="index"
                      >
                        <li>{{ item }}</li>
                      </ul>
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.mechanical-finishing-method-title"
                      )
                    }}
                  </div>
                  <v-col cols="12">
                    <div v-if="mechanicalFinishingMethod.length">
                      <ul
                        v-for="(item, index) in mechanicalFinishingMethod"
                        :key="index"
                      >
                        <li>{{ item }}</li>
                      </ul>
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.finishing-method-title"
                      )
                    }}
                  </div>
                  <v-col cols="12">
                    <div v-if="finishingMethod.length">
                      <ul v-for="(item, index) in finishingMethod" :key="index">
                        <li>{{ item }}</li>
                      </ul>
                    </div>
                    <v-icon v-else small class="ml-2" color="red darken-2">
                      $vuetify.icons.delete
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.other-chemical-finishing-method-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.finishing.otherChemicalFinishingMethod"
                    class="ml-2"
                  >
                    {{ data.finishing.otherChemicalFinishingMethod }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.finishing.other-denim-finishing-method-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.finishing.otherDenimFinishingMethod"
                    class="ml-2"
                  >
                    {{ data.finishing.otherDenimFinishingMethod }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-6 ml-3">
            <v-row>
              <div class="primary--text font-weight-bold">
                {{
                  $t(
                    "preview-materials.textile.finishing.other-mechanical-finishing-method-title"
                  )
                }}
              </div>
              <div
                v-if="data.finishing.otherMechanicalFinishingMethod"
                class="ml-2"
              >
                {{ data.finishing.otherMechanicalFinishingMethod }}
              </div>
              <v-icon v-else small class="ml-2" color="red darken-2">
                $vuetify.icons.delete
              </v-icon>
            </v-row>
          </v-col>
        </v-card-text>

        <!-- #endregion finishing -->

        <!-- #region knitting -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.knitting.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.knitting.completed-title") }}
                  </div>
                  <v-icon
                    v-if="data.knitting.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.cutting-technique-title"
                      )
                    }}
                  </div>
                  <div v-if="data.knitting.cuttingTechnique" class="ml-2">
                    {{ data.knitting.cuttingTechnique }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.knitting-technology-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.knitting.knittingTechnology"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    {{ data.knitting.knittingTechnology }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.knitting.gsm-title") }}
                  </div>
                  <div v-if="data.knitting.gsm" class="ml-2">
                    {{ data.knitting.gsm }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.weft-or-wrap-knitting-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.knitting.weftOrWrapKnitting"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    {{ data.knitting.weftOrWrapKnitting }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.yarn-spinning-technique-title"
                      )
                    }}
                  </div>
                  <div v-if="data.knitting.yarnSpinningTechnique" class="ml-2">
                    {{ data.knitting.yarnSpinningTechnique }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.knitting.gauge-title") }}
                  </div>
                  <div
                    v-if="data.knitting.gauge"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    {{ data.knitting.gauge }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.jersey-or-intarsia-title"
                      )
                    }}
                  </div>
                  <div v-if="data.knitting.jerseyOrIntarsia" class="ml-2">
                    {{ data.knitting.jerseyOrIntarsia }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="12">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.knitting.knitting-diagram-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.knitting.knittingDiagram"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    {{ data.knitting.knittingDiagram }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </v-card-text>
        <!-- #endregion knitting -->

        <!-- #region laundry -->

        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.laundry.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.laundry.completed-title") }}
                  </div>
                  <v-icon
                    v-if="data.laundry.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.textile.laundry.laundry-method-title")
                    }}
                  </div>
                  <div v-if="data.laundry.laundryMethod" class="ml-2">
                    {{ data.laundry.laundryMethod }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="ml-3">
            <div class="row">
              <v-col cols="6"></v-col>
              <v-col cols="12">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.laundry.temperature") }}
                  </div>
                  <div v-if="data.laundry.temperature" class="ml-2">
                    {{ data.laundry.temprature }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </div>
          </v-col>
        </v-card-text>

        <!-- #endregion laundry -->

        <!-- #region printing -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.printing.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.printing.completed-title") }}
                  </div>
                  <v-icon
                    v-if="data.printing.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.printing.printing-chemical-title"
                      )
                    }}
                  </div>
                  <div v-if="data.printing.printingChemical" class="ml-2">
                    {{ data.printing.printingChemical }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.printing.printing-method-title"
                      )
                    }}
                  </div>
                  <v-icon
                    v-if="data.printing.printingMethod"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <div v-else>
                    {{ data.printing.printingMethod }}
                  </div>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.printing.other-printing-method-title"
                      )
                    }}
                  </div>
                  <div v-if="data.printing.otherPrintingMethod" class="ml-2">
                    {{ data.printing.otherPrintingMethod }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <!-- #endregion printing -->

        <!-- #region spinning -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.spinning.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <div class="row">
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.spinning.completed-title") }}
                  </div>
                  <v-icon
                    v-if="data.spinning.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.spinning.nb-ply-per-yarn-title"
                      )
                    }}
                  </div>
                  <div class="ml-2">
                    {{ data.spinning.nbPlyPerYarn }}
                  </div>
                </v-row>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.textile.spinning.nm-final-yarn-title")
                    }}
                  </div>
                  <div v-if="data.spinning.nmFinalYarn" class="ml-2">
                    {{ data.spinning.nmFinalYarn }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.spinning.other-yarn-type-title"
                      )
                    }}
                  </div>
                  <div v-if="data.spinning.otherYarnType" class="ml-2">
                    {{ data.spinning.otherYarnType }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.spinning.yarn-type-title") }}
                  </div>
                  <div v-if="data.spinning.yarnType" class="ml-2">
                    {{ data.spinning.yarnType }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.spinning.yarn-spinning-technique-title"
                      )
                    }}
                  </div>
                  <div v-if="data.spinning.yarnSpinningTechnique" class="ml-2">
                    {{ data.spinning.yarnSpinningTechnique }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.spinning.yarn-finishing-title"
                      )
                    }}
                  </div>
                  <div v-if="data.spinning.yarnFinishing" class="ml-2">
                    {{ data.spinning.yarnFinishing }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.spinning.twisting-direction-title"
                      )
                    }}
                  </div>
                  <div v-if="data.spinning.twistingDirection" class="ml-2">
                    {{ data.spinning.twistingDirection }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
        <!-- #endregion spinning -->

        <!-- #region weaving -->

        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text font-weight-bold text-uppercase">
              {{ $t("preview-materials.textile.weaving.title") }}
            </div>
            <v-divider />
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.weaving.completed-title") }}
                  </div>
                  <v-icon
                    v-if="data.weaving.completed === true"
                    small
                    class="ml-2"
                    color="green darken-2"
                  >
                    fas fa-check
                  </v-icon>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.textile.weaving.gsm") }}
                  </div>
                  <div v-if="data.weaving.gsm" class="ml-2">
                    {{ data.weaving.gsm }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.textile.weaving.hiddles-by-cm-title")
                    }}
                  </div>
                  <div v-if="data.weaving.hiddlesByCm" class="ml-2">
                    {{ data.weaving.hiddlesByCm }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.weaving.weaving-structure-title"
                      )
                    }}
                  </div>
                  <div v-if="data.weaving.weavingStructure" class="ml-2">
                    {{ data.weaving.weavingStructure }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-6 ml-3">
            <v-row align="center" no-gutters>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.weaving.weft-insertion-system-title"
                      )
                    }}
                  </div>
                  <div v-if="data.weaving.weftInsertionSystem" class="ml-2">
                    {{ data.weaving.weftInsertionSystem }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.textile.weaving.yarn-spinning-technique-title"
                      )
                    }}
                  </div>
                  <div v-if="data.weaving.yarnSpinningTechnique" class="ml-2">
                    {{ data.weaving.yarnSpinningTechnique }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>

        <!-- #endregion weaving -->
      </v-card>
      <!-- #endregion TEXTILE -->

      <!-- #region LEATHER -->
      <v-card v-else-if="data && data.type === 'LEATHER'" class="container-card">
        <v-card-title>
          <h3>{{ $t("common.material-forms.details.title") }}</h3>
        </v-card-title>

        <!-- General -->
        <v-card-text>
          <v-col cols="12">
            <div class="text-h6 primary--text">
              {{ $t("preview-materials.leather.general.title") }}
            </div>
            <v-divider color="primary" />
          </v-col>

          <v-col cols="12">
            <v-row align="center" no-gutters>
              <div class="primary--text font-weight-bold">
                {{ $t("preview-materials.leather.general.completed-title") }}
              </div>
              <v-icon
                v-if="data.general_leather.completed === true"
                small
                class="ml-2"
                color="green darken-2"
              >
                fas fa-check
              </v-icon>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.leather.general.animal-kind-title")
                    }}
                  </div>
                  <div v-if="data.general_leather.animalKind" class="ml-2">
                    {{ data.general_leather.animalKind }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t("preview-materials.leather.general.animal-origin-title")
                    }}
                  </div>
                  <div v-if="data.general_leather.animalOrigin" class="ml-2">
                    {{ data.general_leather.animalOrigin }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.leather.general.dyeing-technology-title"
                      )
                    }}
                  </div>
                  <div v-if="data.general_leather.dyeingTechnology" class="ml-2">
                    {{ data.general_leather.dyeingTechnology }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.leather.general.finishing-technology-title"
                      )
                    }}
                  </div>
                  <div
                    v-if="data.general_leather.finishingTechnology"
                    class="ml-2"
                  >
                    {{ data.general_leather.finishingTechnology }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{ $t("preview-materials.leather.general.skin-type-title") }}
                  </div>
                  <div v-if="data.general_leather.skinType" class="ml-2">
                    {{ data.general_leather.skinType }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row align="center" no-gutters>
                  <div class="primary--text font-weight-bold">
                    {{
                      $t(
                        "preview-materials.leather.general.tanning-technology-title"
                      )
                    }}
                  </div>
                  <div v-if="data.general_leather.tanningTechnology" class="ml-2">
                    {{ data.general_leather.tanningTechnology }}
                  </div>
                  <v-icon v-else small class="ml-2" color="red darken-2">
                    $vuetify.icons.delete
                  </v-icon>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>

        <!-- Conctacts -->
        <v-card-text>
          <v-expansion-panels flat style="margin-left: -12px">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="primary--text font-weight-bold">
                  {{
                    $t("preview-materials.leather.general.contact-details-title")
                  }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="d-flex justify-space-around">
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.leather.general.beam-house-contact.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.beam-house-contact.name"
                              )
                            }}
                            {{
                              data.general_leather.beamHouseContact.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.beam-house-contact.email"
                              )
                            }}
                            {{
                              data.general_leather.beamHouseContact.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.beam-house-contact.address"
                              )
                            }}
                            {{
                              data.general_leather.beamHouseContact.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.beam-house-contact.phone"
                              )
                            }}
                            {{
                              data.general_leather.beamHouseContact.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.leather.general.dyeing-contact.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.dyeing-contact.name"
                              )
                            }}
                            {{ data.general_leather.dyeingContact.name || "n/a" }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.dyeing-contact.email"
                              )
                            }}
                            {{
                              data.general_leather.dyeingContact.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.dyeing-contact.address"
                              )
                            }}
                            {{
                              data.general_leather.dyeingContact.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.dyeing-contact.phone"
                              )
                            }}
                            {{
                              data.general_leather.dyeingContact.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="d-flex justify-space-around">
                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.leather.general.finishing-contact.title"
                            )
                          }}
                        </div>
                      </v-col>

                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.finishing-contact.name"
                              )
                            }}
                            {{
                              data.general_leather.finishingContact.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.finishing-contact.email"
                              )
                            }}
                            {{
                              data.general_leather.finishingContact.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.finishing-contact.address"
                              )
                            }}
                            {{
                              data.general_leather.finishingContact.address ||
                              "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.finishing-contact.phone"
                              )
                            }}
                            {{
                              data.general_leather.finishingContact.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="5">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="primary--text font-weight-bold">
                          {{
                            $t(
                              "preview-materials.leather.general.tanning-contact.title"
                            )
                          }}
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <ul>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.tanning-contact.name"
                              )
                            }}
                            {{
                              data.general_leather.tanningContact.name || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.tanning-contact.email"
                              )
                            }}
                            {{
                              data.general_leather.tanningContact.email || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.tanning-contact.address"
                              )
                            }}
                            {{
                              data.general_leather.tanningContact.address || "n/a"
                            }}
                          </li>
                          <li>
                            {{
                              $t(
                                "preview-materials.leather.general.tanning-contact.phone"
                              )
                            }}
                            {{
                              data.general_leather.tanningContact.phone || "n/a"
                            }}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <!-- #endregion LEATHER -->
    </template>
    <template v-else-if="!isLoadingData && data == null">
      <p>Not found</p>
    </template>
    <template v-else>
      <v-card min-height="200">
        <ProgressCircle />
      </v-card>
    </template>



  </v-dialog>
</template>

<script>
import dialogMixin    from "@/mixins/dialog";
import formatter      from "@/mixins/formatter";

export default {
  name: "MaterialFormDetailsDialog",
  components: {
    ProgressCircle: () => import("@/components/Common/ProgressCircle")
  },
  mixins: [dialogMixin, formatter],

  props: {
    materialForm: { type: Object, default: () => null },
  },

  data() {
    return {
      data: null,
      isLoadingData: false,
      chemicalFinishingMethod: [],
      denimFinishingMethod: [],
      finishingMethod: [],
      mechanicalFinishingMethod: [],
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.isLoadingData = true;
      this.$http
        .get(`/material-forms/${this.materialForm.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.data = res.data;
          this.chemicalFinishingMethod =
            this.data.finishing.chemicalFinishingMethod.filter((el) => {
              return el !== "[]";
            });

          this.denimFinishingMethod =
            this.data.finishing.denimFinishingMethod.filter((el) => {
              return el !== "[]";
            });
          this.finishingMethod = this.data.finishing.finishingMethod.filter(
            (el) => {
              return el !== "[]";
            }
          );
          this.mechanicalFinishingMethod =
            this.data.finishing.mechanicalFinishingMethod.filter((el) => {
              return el !== "[]";
            });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },
  },
};
</script>

<style scoped>
.container-card > * ::first-letter {
  text-transform: uppercase !important;
}

li {
  color: black;
}

li::marker {
  color: #155041 !important;
  font-size: 150%;
}
</style>
